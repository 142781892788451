import { CountriesList } from '@/shared/constants/countries-list';
import { LanguagesList } from '@/shared/constants/languages-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import {
  DisclosureType,
  HighMediumLowList,
  HighMediumLowType,
  InformationDisclosureList,
} from '@/shared/lib/sj-orm/models/asset.dto';
import { DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import { ContactType } from '@/shared/lib/sj-orm/models/contact.dto';
import { PersonalIdentifierType } from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import { PrivateDocumentDto } from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export enum CategoryType {
  family = 'family',
  money = 'money',
  personal = 'personal',
}

export const CategoryList = [
  {
    label: 'Family',
    key: CategoryType.family,
  },
  {
    label: 'Money',
    key: CategoryType.money,
  },
  {
    label: 'Personal',
    key: CategoryType.personal,
  },
];

export enum CertifiedByType {
  none = 'none',
  notary = 'notary',
  governmentAuthority = 'governmentAuthority',
  consulate = 'consulate',
  signatureWitness = 'signatureWitness',
  other = 'other',
}

export const CertifiedByList = [
  {
    label: 'None',
    key: CertifiedByType.none,
  },
  {
    label: 'Notary',
    key: CertifiedByType.notary,
  },
  {
    label: 'Government authority',
    key: CertifiedByType.governmentAuthority,
  },
  {
    label: 'Signature witness',
    key: CertifiedByType.signatureWitness,
  },
  {
    label: 'Other',
    key: CertifiedByType.other,
  },
];

export type BilateralDtoType = Omit<
  PrivateDocumentDto,
  'id' | 'privateType' | 'type' | 'privateCategory'
> & {
  name: string;
  country: string;
  content?: string;
  category?: CategoryType;
  signatory1?: DtoId[];
  signatory2?: DtoId[];
  number?: string;
  startDate?: number;
  stopDate?: number;
  updateRequirementsReason?: string;
  updateRequirementsDate?: number;
  informationDisclosure?: DisclosureType;
  informationDisclosureDetails?: string;
  language?: string;
  certifiedBy?: CertifiedByType;
  myTodo?: boolean;
  myTodoComments?: string;
  notes?: string;
  scan?: string;
  storageLocation?: DtoId[];
  comments?: string;
  priority?: HighMediumLowType;
};

export type BilateralDtoHelpType = {
  name: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  content?: QuestionType.TEXT;
  category?: QuestionType.SELECT_FROM_LIST;
  signatory1?: QuestionType.PICK_ARRAY_OF_DTO;
  signatory2?: QuestionType.PICK_ARRAY_OF_DTO;
  number?: QuestionType.TEXT;
  startDate?: QuestionType.DATE_TIME;
  stopDate?: QuestionType.DATE_TIME;
  updateRequirementsReason?: QuestionType.TEXT;
  updateRequirementsDate?: QuestionType.DATE_TIME;
  informationDisclosure?: QuestionType.SELECT_FROM_LIST;
  informationDisclosureDetails?: QuestionType.TEXT_MULTILINE;
  language?: QuestionType.SELECT_FROM_LIST;
  certifiedBy?: QuestionType.SELECT_FROM_LIST;
  myTodo?: QuestionType.CHECKBOX;
  myTodoComments?: QuestionType.TEXT_MULTILINE;
  notes?: QuestionType.TEXT_MULTILINE;
  scan?: QuestionType.FILE_UPLOAD;
  storageLocation?: QuestionType.PICK_ARRAY_OF_DTO;
  comments?: QuestionType.TEXT_MULTILINE;
  priority?: QuestionType.SELECT_FROM_LIST;
};

type BilateralFormType = TDtoWrapper<BilateralDtoType, BilateralDtoHelpType>;

export const BilateralForm: BilateralFormType = {
  name: {
    type: QuestionType.TEXT,
    label: '*Document name',
    required: true,
    value: '',
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: '*Country',
    items: CountriesList,
    withSearch: true,
    required: true,
    value: '',
  },
  content: {
    type: QuestionType.TEXT,
    label: 'Content',
  },
  category: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Category',
    items: CategoryList,
  },
  signatory1: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'Signatory 1',
    items: 'personalIdentifiersDocuments',
    dtoType: PersonalIdentifierType.BIRTH_CERTIFICATE,
  },
  signatory2: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'Signatory 2',
    items: 'personalIdentifiersDocuments',
    dtoType: PersonalIdentifierType.BIRTH_CERTIFICATE,
  },
  number: {
    type: QuestionType.TEXT,
    label: 'Document number',
  },
  startDate: {
    type: QuestionType.DATE_TIME,
    label: 'Start date',
  },
  stopDate: {
    type: QuestionType.DATE_TIME,
    label: 'Stop date',
  },
  updateRequirementsReason: {
    type: QuestionType.TEXT,
    label: 'Update requirements: reason',
  },
  updateRequirementsDate: {
    type: QuestionType.DATE_TIME,
    label: 'Update requirements: date',
  },
  informationDisclosure: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Information disclosure',
    items: InformationDisclosureList,
  },
  informationDisclosureDetails: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'Information disclosure details',
  },
  language: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Document language',
    items: LanguagesList,
  },
  certifiedBy: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Certified by',
    items: CertifiedByList,
  },
  myTodo: {
    type: QuestionType.CHECKBOX,
    label: 'My todo',
  },
  myTodoComments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'My todo comments',
    showIf: {
      myTodo: {
        value: true,
      },
    },
  },
  notes: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'Notes',
  },
  scan: {
    type: QuestionType.FILE_UPLOAD,
    label: 'Document scan',
  },
  storageLocation: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'Storage location',
    items: 'contacts',
    dtoType: ContactType.BANK,
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'Comments',
  },
  priority: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Priority',
    items: HighMediumLowList,
  },
};
