import {
  FC,
  memo,
  PropsWithChildren,
  startTransition,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import { KeyPair } from '@/shared/lib/secure-json/core/crypto-core/types';
import { useSecureJsonCollectionsStore } from '@/shared/lib/stores/secure-json-collections.store';
import { useFileLoader } from '@/shared/lib/synchronizer/hooks/use-file-loader';
import { useGqlLoader } from '@/shared/lib/synchronizer/hooks/use-gql-loader';
import { useSynchronizer } from '@/shared/lib/synchronizer/hooks/use-synchronizer';
/*import {
  SyncQueueJobMethod,
  useSyncQueueStore,
} from '@/shared/lib/synchronizer/sync-queue.store';*/
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';

const synchronizationDelay = 200;

const SynchronizerProviderComponent: FC<PropsWithChildren> = ({
  children,
}): JSX.Element => {
  const { keyPair } = useKeyPairStore();

  const [isSync, setIsSync] = useState(false);

  const graphQLLoader = useGqlLoader();
  const fileLoader = useFileLoader();
  const isModeExported = process.env.NEXT_PUBLIC_APP_MODE === 'EXPORTED';

  const store = useSecureJsonCollectionsStore();
  const synchronizer = useSynchronizer(
    keyPair ?? ({} as KeyPair),
    isModeExported ? fileLoader : graphQLLoader,
    // 'Store init',
  );

  let timeout: NodeJS.Timeout | null = null;

  const execute = useCallback(() => {
    timeout = null;
    startTransition(() => {
      synchronizer?.sync().finally(() => setIsSync(false));
    });
  }, [store]);

  const unloadHandler = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = true;
  };

  useLayoutEffect(() => {
    if (keyPair?.privateKey) {
      setIsSync(true);
      timeout = setTimeout(execute, synchronizationDelay);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [store, keyPair]);

  useEffect(() => {
    if (isSync && typeof window.onbeforeunload !== 'function') {
      window.onbeforeunload = unloadHandler;
    } else {
      window.onbeforeunload = null;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [isSync]);

  return <>{children}</>;
};

export default memo<PropsWithChildren>(SynchronizerProviderComponent);
