import { Flex } from 'antd';
import styled from 'styled-components';

import { COLORS } from '@/shared/constants/colors';

import { Icon } from '../icon';

export const FastTrackMenuButton = ({
  title,
  description,
  onClick,
}: {
  title: string;
  description: string;
  onClick: () => void;
}) => {
  return (
    <NavigationButtonWrapper onClick={() => onClick()}>
      <Flex justify="space-between">
        <Title>{title}</Title>

        <Icon icon="arrow_forward" color={COLORS.colorPrimary} fontSize={16} />
      </Flex>
      <Descrpition>{description}</Descrpition>
    </NavigationButtonWrapper>
  );
};

const NavigationButtonWrapper = styled.div`
  padding: 8px 0;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

const Title = styled.p`
  font-size: 16px;
  color: ${COLORS.colorPrimary};
`;

const Descrpition = styled.p`
  margin-top: 4px;
  margin-right: 20px;
  font-size: 12px;
`;
